'use client';

import { Button, TextField } from '@mui/material';
import { createClientComponentClient } from '@supabase/auth-helpers-nextjs';
import clsx from 'clsx';
import Link from 'next/link';
import { useForm } from 'react-hook-form';
import Logo from '@/assets/besmart_logo_typo.svg';
import styles from './page.module.scss';
import type { Database } from '@/lib/database.types';

type FormData = {
  email: string;
  password: string;
};

export default function Login() {
  const { register, handleSubmit } = useForm<FormData>();

  const supabase = createClientComponentClient<Database>();

  const handleSignIn = async (data: FormData) => {
    try {
      await supabase.auth.signInWithPassword({
        email: data.email,
        password: data.password,
      });
      // 確実にuseAuthを発火させるためにリロードをかける
      window.location.href = '/';
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={clsx(styles.wrap, 'py-2 px-6 flex flex-col justify-center items-center')}>
      <Logo className={styles.logo} />

      <form onSubmit={handleSubmit(handleSignIn)}>
        <div className={clsx(styles.card, 'flex w-full flex-col gap-8')}>
          <div className="flex w-full flex-col gap-4">
            <TextField
              type="email"
              label="メールアドレス"
              className="w-full"
              {...register('email')}
            />
            <TextField
              type="password"
              label="パスワード"
              className="w-full"
              {...register('password')}
            />
          </div>
          <div className="flex">
            <Button type="submit" variant="contained" className="w-full">
              ログイン
            </Button>
          </div>
          <div>
            パスワードを忘れた方は<Link href="/login/forgetpassword">こちら</Link>
          </div>
        </div>
      </form>
    </div>
  );
}
